<template>
  <div>
    <!-- 参赛须知 -->
    <div class="container">
      <!-- 参赛须知显示框 -->
      <div class="notice-box">
        <div class="notice-title">
          参赛须知
        </div>
        <div class="notice-content">
          <div v-html="noticeContent" />
        </div>
      </div>

      <!-- 同意按钮 -->
      <div class="consent">
        <div class="consent-box">
          <span class="announce">
            <el-checkbox v-model="isConsent" class="check svg">我同意并做出上述承诺</el-checkbox>
          </span>
          <div class="btn-place">
            <el-button style="width: 120px;" type="primary" @click="startEnroll">开始报名</el-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import '@/icons/consent'
import '@/icons/circle'
import { retrieveContestNotice } from '@/api/homepage'

export default {
  data() {
    return {
      isConsent: false,
      noticeContent: ''
    }
  },
  mounted() {
    this.getContestNotice()
  },
  methods: {
    getContestNotice() {
      retrieveContestNotice().then(res => {
        this.noticeContent = res.data.content
      })
    },
    // 是否同意
    consentAnnounce() {
      this.isConsent = !this.isConsent
    },
    // 开始报名
    startEnroll() {
      if (this.isConsent) {
        this.$router.push({ path: 'enroll' })
      } else {
        this.$notify({
          title: '警告',
          message: '请确认承诺',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 510px;
  width: 1000px;
  /* border: 1px solid red; */
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.notice-content {
  display: inline-block;
  width: 90%;
  margin: auto;
  /* border: 1px solid red; */
  text-align: left;
  height: 390px;
  overflow: auto;
}

.consent {
  display: block;
}

.consent-box {
  display: inline-block;
  margin: auto;
  margin-top: 50px;
  width: 400px;
  height: 50px;
  /* border: 1px solid red; */

}

.announce {
  float: left;
  /* display: inline-block; */
  margin-top: 5px;
  font-size: 13px;
  height: 40px;
  width: 155px;
  color: rgb(51, 51, 51);
  /* border: 1px solid red; */
}

.check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--finish-color);
  border-color: var(--finish-color);
  border-radius: 50%;
}

.check /deep/ .el-checkbox__inner {
  border-radius: 50%;
}

.svg {
  float: left;
  /* display: inline-block; */
  /* border: 1px solid white; */
  margin-right: 5px;
  margin-top: 7px;
}

.svg {
  cursor: pointer;
}

.announce-text {
  float: right;
  /* display: inline-block; */
  /* line-height: 20px; */
  margin-top: 7px;
  /* border: 1px solid white; */
}

.btn-place {
  float: left;
  margin-left: 30px;
  /* display: inline-block; */
  /* width: 200px; */
}

</style>
